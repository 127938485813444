// ProgressBar.js
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const ProgressBar = ({ inboundStatus, outboundStatus }) => {
  // Calculate progress here
  return (
    <View>
      {/* Render progress bar here */}
    </View>
  );
};

const styles = StyleSheet.create({
  // Add your progress bar styles here
});

export default ProgressBar;
