import React, { useState, useEffect } from 'react';
import { View, Text, Button, StyleSheet, TextInput, Image } from 'react-native';
import { AsyncStorage } from 'react-native';
import { TouchableOpacity, Animated } from 'react-native';
import { useParams } from 'react-router-dom'

const AddressValidator = ({ history }) => {
  const [response, setResponse] = useState(null);
  const [boxData, setBoxData] = useState(null);
  const [addressLine, setAddressLine] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [boxOwner, setBoxOwner] = useState('');
  const currentDate = new Date();
  const { id } = useParams()
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  // Check if tomorrow is a Sunday
  if (tomorrow.getDay() === 0) {
    tomorrow.setDate(currentDate.getDate() + 2);
  }

  const formattedDate = tomorrow.toISOString().slice(0, 10).replace(/-/g, '');

  const sendRequest = async () => {
    try {
      const data = {
        addressLine: addressLine,
        city: city,
        state: state,
        firstName: firstName,
        lastName: lastName,
        boxOwner: Number(boxOwner),
        zip: zip,
        date: formattedDate,
      };

      const response = await fetch('https://xidm-bhau-tcuw.n7.xano.io/api:f7dSMvYs/createPickup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const jsonResponse = await response.json();
      setResponse(jsonResponse);

      console.log(jsonResponse.result);
      if (jsonResponse.result.resultCode === 200) {
        window.open("https://app.kothara.com/complete", "_self");
      } else {
        alert('Please enter a valid address');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBoxData = async () => {
    const data = {
      box_tracker_id: Number(id),
    };
    try {
      const response = await fetch('https://xidm-bhau-tcuw.n7.xano.io/api:hzahvnDj:v1/box_tracker', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const jsonResponse = await response.json();


      console.log(jsonResponse);
      setBoxData(jsonResponse);
      setFirstName(jsonResponse.firstName);
      setLastName(jsonResponse.lastName);
      setAddressLine(jsonResponse.addressLine);
      setCity(jsonResponse.city);
      setState(jsonResponse.state);
      setZip(jsonResponse.zip);
      setBoxOwner(jsonResponse.boxOwner);



    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBoxData();
  }, []);



return (
  <View style={styles.container}>
    <View style={styles.header}>
      <Text style={styles.headerText}>Kothara</Text>
      <Text style={styles.headerSubtitle}>Returns</Text>
    </View>
    <Image source={require('../assets/box.png')} style={styles.boxImage}/>
    <Text style={styles.welcomeMessage}>Hi {firstName}!</Text>
    <Text style={styles.description}>We will make this quick.</Text>
    <View style={styles.separator}></View>
    <View style={styles.form}>
      <Text style={styles.addressTitle}>Your Address:</Text>
      <View style={styles.addressContainer}>
        <Text style={styles.addressLine}>{addressLine.toUpperCase()}</Text>
        <Text style={styles.addressDetails}>{city.toUpperCase()} {state.toUpperCase()}, {zip.toUpperCase()}</Text>
      </View>
      <View style={styles.separator}></View>
      <Text style={styles.confirmTitle}>Everything Look Right?</Text>
      <View style={styles.buttonContainer}>
        <TouchableOpacity onPress={sendRequest} style={styles.confirmButton}>
          <Text style={styles.confirmButtonText}>Looks good to me!</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.editButton}>
          <Text style={styles.editButtonText}>Hold up</Text>
        </TouchableOpacity>
      </View>
    </View>
  </View>
);
};

const styles = StyleSheet.create({
container: {
  flex: 1,
  alignItems: 'center',
  paddingTop: 20,
  paddingBottom: 40,
  paddingHorizontal: 20,
  backgroundColor: '#F7F7F7',
},
header: {
  width: '100%',
  marginBottom: 30,
},
headerText: {
  fontSize: 30,
  fontWeight: '600',
},
headerSubtitle: {
  fontSize: 50,
  fontWeight: 'bold',
},
boxImage: {
  width: 200,
  height: 200,
  resizeMode: 'contain',
  marginTop: 20,
  marginBottom: 20,
},
welcomeMessage: {
  fontSize: 20,
  fontWeight: 'bold',
  marginTop: 20,
  marginBottom: 5,
},
description: {
  fontSize: 16,
},
separator: {
  width: '100%',
  height: 1,
  backgroundColor: '#E3E3E3',
  marginTop: 30,
  marginBottom: 20,
},
form: {
  backgroundColor: '#FFFFFF',
  paddingHorizontal: 30,
  paddingVertical: 20,
  borderRadius: 10,
  width: '100%',
},
addressTitle: {
  fontSize: 25,
  fontWeight: 'bold',
  marginBottom: 5,
},
addressContainer: {
  width: '100%',
},
addressLine: {
  fontSize: 18,
},
addressDetails: {
  fontSize: 18,
},
confirmTitle: {
  fontSize: 25,
  fontWeight: 'bold',
  marginTop: 20,
  marginBottom: 20,
},
buttonContainer: {
flexDirection: 'row',
justifyContent: 'space-between',
marginTop: 10,
},
confirmButton: {
width: '48%',
backgroundColor: '#007AFF',
padding: 12,
borderRadius: 5,
justifyContent: 'center',
},
confirmButtonText: {
textAlign: 'center',
fontWeight: '500',
color: 'white',
},
editButton: {
width: '48%',
backgroundColor: '#E3E3E3',
padding: 12,
borderRadius: 5,
justifyContent: 'center',
},
editButtonText: {
textAlign: 'center',
fontWeight: '500',
color: '#333333',
},
});

export default AddressValidator;