import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  FlatList,
  Alert,
  Modal, // Add this import
  TouchableHighlight, // Add this import
} from "react-native";
import { MaterialIcons, Ionicons } from "@expo/vector-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ProgressBar from './ProgressBar';
import { MaterialCommunityIcons } from '@expo/vector-icons';


const TrackShipment = ({ history }) => {
  const [search, setSearch] = useState("");
  const [dataResponse, setDataResponse] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [labelURLs, setLabelURLs] = useState({ labelURL: "", returnLabelURL: "" });

  const navigate = useNavigate();
  const handleLabelDownload = (labelURL, returnLabelURL) => {
    setLabelURLs({ labelURL, returnLabelURL });
    setModalVisible(true);
  };
  function getStatusString(outboundStatus, inboundStatus, inboundDesc, outboundDesc) {
    if (outboundStatus !== "01") {
      return outboundDesc;
    } else {
      return inboundDesc;
    }
  }



  useEffect(() => {
    if (window.localStorage.getItem("authToken") === null) {
      navigate("/");
    }

    const fetchData = async () => {
      const result = await axios({
        method: "post",
        url: endpoint,
        headers: {
          Authorization: String(window.localStorage.getItem("authToken")),
        },
      });
      setDataResponse(result.data);
    };

    fetchData();
  });

  const filteredData = dataResponse.filter((item) => {
    return (
      item.lastName.toLowerCase().includes(search.toLowerCase()) ||
      item.addressLine.toLowerCase().includes(search.toLowerCase()) ||
      item.city.toLowerCase().includes(search.toLowerCase()) ||
      item.state.toLowerCase().includes(search.toLowerCase()) ||
      item.zip.toLowerCase().includes(search.toLowerCase())
    );
  });

  const endpoint = 'https://xidm-bhau-tcuw.n7.xano.io/api:hzahvnDj:v1/boxList';

  return (
    <View style={styles.container}>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Select a route.</Text>
            <View style={{ flexDirection: "row", alignContent: "space-between" }}>
              <TouchableOpacity
                style={styles.modalButton}
                onPress={() => {
                  setModalVisible(!modalVisible);
                  window.open(labelURLs.labelURL, "_blank");
                }}
              >
                <Text style={styles.modalButtonText}>Outbound</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.modalButton}
                onPress={() => {
                  setModalVisible(!modalVisible);
                  window.open(labelURLs.returnLabelURL, "_blank");
                }}
              >
                <Text style={styles.modalButtonText}>Return</Text>
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              style={{ marginTop: 10 }}
              onPress={() => {
                setModalVisible(!modalVisible);
              }}
            >
              <Text style={[styles.modalButtonText, { color: "red" }]}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <View style={{
        width: "450px",
        maxWidth: "90%"
      }}>
        <View style={styles.header}>
          <Text style={styles.title}>Track Shipment</Text>
          <MaterialIcons name="track-changes" size={32} color="#1D1D1F" />
        </View>
        <TextInput
          style={styles.input}
          placeholder="Search by name, address, city, state, or zip"
          onChangeText={(text) => setSearch(text)}
          value={search}
        />
        <FlatList
          data={filteredData}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item }) => (
            <View style={styles.itemContainer}>
              <Text style={{ fontWeight: 600, fontSize: 16, marginBottom: 10 }}>{getStatusString(item.outboundStatus, item.inboundStatus, item.inboundDesc, item.outboundDesc)}</Text>

              <View>
                <Text style={styles.accountText}>To: {item.lastName.toUpperCase()}</Text>
                <Text style={styles.accountText}>{item.addressLine.toUpperCase()}</Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.accountText}>{item.city.toUpperCase()} </Text>
                  <Text style={styles.accountText}>{item.state.toUpperCase()},</Text>
                  <Text style={[styles.accountText, { marginBottom: 10 }]}> {item.zip.toUpperCase()}</Text>
                </View>
              </View>
              

              <View style={styles.item}>
              <View style={[styles.item, { flexDirection: "row", width: "100%" }]}>
                <TouchableOpacity
                  onPress={() => handleLabelDownload(item.labelURL, item.returnLabelURL)}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text style={[styles.itemHeader, { textAlign: "center" }]}>Labels</Text>
                    <Ionicons name="ios-download" size={35} color="#1D1D1F" style={{ alignSelf: "center", marginLeft: 10, marginBottom: 7 }} />
                  </View>


                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => handleLabelDownload("https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=" +
                    item.trackingNumber +
                    "%2C&tABt=false", "https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=" +
                    item.inboundTracking +
                  "%2C&tABt=false")}
                >
                  <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-around", width: "100%" }}>
                    <Text style={[styles.itemHeader, { textAlign: "center", marginTop: 6, marginLeft: 20 }]}>Tracking</Text>
                    <MaterialCommunityIcons style={{ alignSelf: "center", marginLeft: 10, marginTop: 5 }} name="package" size={35} color="black" />
                  </View>
                </TouchableOpacity>
              </View>


              </View>
              
            </View>
          )}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 40,
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  title: {
    fontFamily: "System",
    fontWeight: "bold",
    fontSize: 30,
    color: "#1D1D1F",
  },
  input: {
    backgroundColor: "#EDEDED",
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 10,
    marginBottom: 20,
    width: "100%",
  },
  itemContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    backgroundColor: "#F3F3F3",
    padding: 12,
    borderRadius: 8,
    marginBottom: 10,
  },
  item: {
    flexBasis: "55%",
    marginBottom: 8,
  },
  itemHeader: {
    fontWeight: "600",
    fontSize: 14,
    marginBottom: 2,
    color: "#1D1D1F",
  },
  itemText: {
    fontSize: 14,
    color: "#1D1D1F",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  modalView: {
    width: "400px",
    maxWidth: "80%",
    backgroundColor: "white",
    borderRadius: 20,
    padding: 30,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 20,
    textAlign: "center",
  },
  modalButton: {
    backgroundColor: "#EDEDED",
    borderRadius: 10,
    padding: 10,
    maxWidth: "100%",
    marginHorizontal: 5,

    marginBottom: 10,
    elevation: 2,
  },
  modalButtonText: {
    color: "#1D1D1F",
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
  },
  accountText: {
    fontSize: 15,
    fontWeight: "bold",
  },
});

export default TrackShipment;

