import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Button,
  StyleSheet,
  TextInput,
  ImageBackground,
  Modal,
  Alert,
} from "react-native";
import { AsyncStorage } from "react-native";
import { TouchableOpacity, Animated } from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";
import { QrReader } from 'react-qr-reader';
import { MaterialIcons } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import QrScanner from 'react-qr-scanner';
import { useNavigate } from "react-router-dom";
import axios from "axios";
const CreateShipment = ({ history }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [labelURLs, setLabelURLs] = useState({ labelURL: "", returnLabelURL: "" });
  const image = { uri: "https://reactjs.org/logo-og.png" };
  const [addressLine, setAddressLine] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [boxId, setBoxId] = useState("");
  const [UID, setUID] = useState("");
  const [showForm, setShowForm] = useState(false);
  const data = window.localStorage.getItem("authToken");
  console.log(data);
  const navigate = useNavigate();
  const logOut = async () => {
    localStorage.clear();
    navigate("/");
  };
  useEffect(() => {
    if (window.localStorage.getItem("authToken") === null) {
      navigate("/");
    }
  });
  const handleLabelDownload = (labelURL, returnLabelURL) => {
    setLabelURLs({ labelURL, returnLabelURL });
    setModalVisible(true);
  };
  const extractNumbers = (str) => {
    if (str === null || str === undefined) {
      return '';
    }
    let numbers = '';
    for (let i = 0; i < str.length; i++) {
      const char = str[i];
      if (!isNaN(char)) {
        numbers += char;
      }
    }
    return numbers;
  };
  const handleScan = (result) => {
    console.log(result);
    if (result === null || result === undefined) {
      return;
    }
    setBoxId(removeAfterSemicolon(result.text));
    setShowForm(true);
  };
  const removeAfterSemicolon = (text) => {
    const parts = text.split(";");
    return parts[0];
  };
  function openPopup() {

  }
  const createShipment = async () => {
    const baseURL = 'https://xidm-bhau-tcuw.n7.xano.io/api:6SZapY9o:v1/createLabel';
    const authHeader = { Authorization: String(window.localStorage.getItem("authToken")) };
    const requestBody = {
      street: addressLine,
      city: city,
      state: state,
      zip: zip,
      name: firstName + " " + lastName,
      boxID: Number(extractNumbers(boxId)),
    };

    try {
      const response = await axios.post(baseURL, requestBody, { headers: authHeader });
      console.log(response.data);
      
      handleLabelDownload(response.data.response.result.pdf, response.data.response.result.return)
      setAddressLine("");
      setCity("");
      setState("");
      setZip("");
      setFirstName("");
      setLastName("");
      setBoxId("");
      setShowForm(false);
    } catch (error) {
      console.error(error);
      alert(error.response.data.message);
    }
  };
  const handleError = (error) => {
    console.error(error);
  };
 

  return (
    <ImageBackground
      source={require("./background.png")}
      resizeMode="cover"
      style={styles.image}
    >
      <View style={styles.container}>
      <Modal
  animationType="slide"
  transparent={true}
  visible={modalVisible}
  onRequestClose={() => {
    setModalVisible(false);
  }}
>
  <View style={styles.centeredView}>
    <View style={styles.modalView}>
      <Text style={styles.modalText}>Which label would you like to download?</Text>
      <Text style={[styles.modalText, {fontSize: 12,
    fontWeight: "300", marginBottom: 20}]}>Note: You can download these labels later from the Track Shipment page. The return label should be printed and placed inside the box before shipping.</Text>
      <View style={{flexDirection: "row", alignContent: "space-around"}}>
      <TouchableOpacity
        style={styles.modalButton}
        onPress={() => {
          
          window.open(labelURLs.labelURL, "_blank");
        }}
      >
        <Text style={styles.modalButtonText}>Shipping</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.modalButton}
        onPress={() => {
          
          window.open(labelURLs.returnLabelURL, "_blank");
        }}
      >
        <Text style={styles.modalButtonText}>Return</Text>
      </TouchableOpacity>
      </View>
      <TouchableOpacity
        style={{marginTop: 10}}
        onPress={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <Text style={[styles.modalButtonText, {color: "red"}]}>Cancel</Text>
      </TouchableOpacity>
      
    </View>
  </View>
</Modal>
        <Text style={styles.title}>New Shipment</Text>
        
        <View style={styles.form}>
        {showForm && (
          <View style={styles.nameInputContainer}>
            <TextInput
              placeholder="First Name"
              value={firstName}
              onChangeText={setFirstName}
          style={[styles.input, { width: "48%", marginBottom: -5, }]}
        />
        <TextInput
          placeholder="Last Name"
          value={lastName}
          onChangeText={setLastName}
          style={[styles.input, { width: "48%", marginBottom: -5, }]}
        />
      </View>
      )}
      
      {showForm && (
      <TextInput
        placeholder="Address Line"
        value={addressLine}
        onChangeText={setAddressLine}
        style={[styles.input]}
      />
      )}
      {showForm && (
      <View style={styles.inputContainer}>
        <TextInput
          placeholder="City"
          value={city}
          onChangeText={setCity}
          style={[styles.input, { width: "30%" }]}
        />
        <TextInput
          placeholder="State"
          value={state}
          onChangeText={setState}
          style={[styles.input, { width: "30%" }]}
        />
        <TextInput
          placeholder="Zip"
          inputMode="numeric"
          value={zip}
          onChangeText={setZip}
          style={[styles.input, { width: "30%" }]}
        />
       
      </View>
      )}
      
        
      
      
      
      {!showForm && (
      <View style={styles.qrContainer}>
        <View>
        <Text style={[styles.scanText, {fontSize: 30}]}>Scan Rebox</Text>
       
        </View>
    
        <View style={styles.qrReaderContainer}>
        <QrScanner
       
          style={styles.qrScanner}
          onError={handleError}
          onScan={handleScan}
          facingMode="rear"
          
          />
        

        </View>
      </View>
       )}
       {showForm && (
        
      <View style={styles.createShipmentButton}>
      <TouchableOpacity style={styles.button} onPress={createShipment}>
          <Text style={styles.buttonText}>Create Shipment</Text>
        </TouchableOpacity>
      </View>
       )}
      
    </View>
  </View>
</ImageBackground>
);
};

const styles = StyleSheet.create({
  container: {
  flex: 1,
  alignItems: "center",
  padding: 20,
  paddingTop: 40,
  backgroundColor: "#F3F3F3",
  },
  qrScanner: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
    overflow: 'hidden',
    
  },
  title: {
  fontWeight: "bold",
  fontSize: 40,
  marginBottom: 30,
  color: "#333",
  },
  inputContainer: {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  flex: 1,
  },
  nameInputContainer: {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 15,
  },
  input: {
    backgroundColor: "#FFFFFF",
    padding: 15,
    
    marginVertical: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    color: "#333",
  },
  form: {
  width: "450px",
  backgroundColor: "white",
  maxWidth: "95%",
  padding: 20,
  borderRadius: 10,
  shadowColor: "black",
  shadowOffset: { width: 1, height: 1 },
  shadowOpacity: 0.1,
  shadowRadius: 4,
  },
  qrContainer: {
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  
  },
  scanText: {
  fontWeight: "bold",
  fontSize: 20,
  color: "#333",
  },
  boxIdText: {
  fontWeight: "normal",
  fontSize: 18,
  color: "#333",
  marginBottom: 10,
  },
  qrReaderContainer: {
    width: "40%",
    height: "100%",
    borderRadius: 10,
    overflow: "hidden",
  },
  createShipmentButton: {
  marginTop: 30,
  },
  image: {
  flex: 1,
  justifyContent: "center",
  },
  button: {
    backgroundColor: "#007AFF",
    marginTop: -10,
    borderRadius: 10,
    paddingVertical: 12,
    
    alignItems: "center",
  },
  buttonText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  modalView: {
    width: "400px",
    maxWidth: "80%",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 30,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
    width: 0,
    height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    },
    modalText: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 5,
    textAlign: "center",
    },
    modalButton: {
    backgroundColor: "#EDEDED",
    borderRadius: 10,
    padding: 10,
    maxWidth: "100%",
    marginHorizontal: 5,
    
    marginBottom: 10,
    elevation: 2,
    },
    modalButtonText: {
    color: "#1D1D1F",
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
    },
  });

export default CreateShipment;
